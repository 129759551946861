<template>
  <div id="clients">
    <div class="container-fluid">
      <!-- Add Edit Section -->
      <div class="update-price-section" v-if="canAccess(['update-currency'])">
        <b-modal id="update-price-modal" hide-header-close :cancel-title="$t('close')" :ok-title="$t('save')"
                 :title="$t('currencies')" @ok="submit">
          <b-form class="d-grid grid-cols-1 gap-5">
            <div class="img-preview d-flex justify-content-center align-items-center">
              <img class="w-100 h-100" v-if="imgPreview" :src="imgPreview" alt="">
              <span v-else>{{ $t('img-preview') }}</span>
            </div>
            <div class="feild pt-3">
              <b-form-file
                type="text"
                @change="getImg($event)"
                :placeholder="$t('upload-img')"
                required
              ></b-form-file>
            </div>
            <div class="feild">
              <label for="">{{ $t('name') }}</label>
              <b-form-input
                type="text"
                min="1"
                placeholder=""
                v-model="payload.real_name"
                required
              ></b-form-input>
            </div>
            <div class="feild">
              <label for="">{{ $t('price') }}</label>
              <b-form-input
                type="number"
                min="1"
                placeholder=""
                v-model="payload.ex_price"
                required
              ></b-form-input>
            </div>
          </b-form>
        </b-modal>
      </div>
      <!-- Filters Section -->
      <div class="filters-section">
        <iq-card class="w-100">
          <template v-slot:body>
            <div class="filter-container" @change="filtering">
              <b-form-input
                type="text"
                :placeholder="$t('filter-name')"
                v-model="filterData.name"
                required
              ></b-form-input>
            </div>
          </template>
        </iq-card>
      </div>
      <!-- Tabel Section -->
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('currency-list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button variant="primary" @click="addInit" size="sm">New Clients</b-button> -->
          </template>
          <template v-slot:body>
            <b-table responsive v-if="canAccess(['get-currency'])" :items="currency" :fields="fields"
                     class="mb-0 table-borderless">
              <template v-slot:cell(details)="data">
                <img :src="data.item.image" width="50px" class="rounded" alt="">
              </template>
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center">
                  <b-button v-if="canAccess(['update-currency'])" v-b-tooltip.top="$t('ed-price')"
                            variant=" iq-bg-success" @click="updateInit(data.item)" size="md"><i
                    class="ri-exchange-dollar-line p-0"></i></b-button>
                </div>
              </template>
            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <!-- <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
                @change="getCurrency(filterData)"
                v-model="filterData.page"
                :total-rows="currency.total"
                :per-page="currency.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Currency',
  data () {
    return {
      imgPreview: '',
      filterData: {
        name: ''
      },
      payload: {
        id: '',
        real_name: '',
        image: '',
        ex_price: '',
        _method: 'PUT'

      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('name'),
          key: 'real_name'
        },
        {
          label: this.$t('img'),
          key: 'details'
        },
        {
          label: this.$t('currency-name'),
          key: 'currency_name'
        },
        {
          label: this.$t('ex-price'),
          key: 'external_ex_price'
        },
        {
          label: this.$t('price'),
          key: 'ex_price'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getCurrency(this.filterData)
    },
    addInit () {
      this.$bvModal.show('clients-modal')
    },
    updateInit (item) {
      this.payload.id = item.id
      this.payload.ex_price = item.ex_price
      this.$bvModal.show('update-price-modal')
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.updateCurrency({
        id: this.payload.id,
        data: this.returnData(this.payload)
      }).then(() => {
        this.$bvModal.hide('update-price-modal')
        this.getCurrency()
      })
    },
    getImg (event) {
      this.imgPreview = URL.createObjectURL(event.target.files[0])
      this.payload.image = event.target.files[0]
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getCurrency(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getCurrency()
  }
}
</script>
<style>
.img-preview {
  height: 200px;
  border: 2px dashed #ccc;
}

.img-preview img {
  object-fit: contain;
}

.show-img {
  background-color: rgba(0, 0, 0, .8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
